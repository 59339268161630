import React from 'react';
import styled from 'styled-components';

import Modal from 'components/UI/Modal/ModalV2';
import parse from 'html-react-parser';

const Content = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000;
`;

const ModalDetail = (props) => {
  const { isOpen, onCancel, onOk, text } = props;
  const formattedText = text?.replace(/\r\n/g, '<br />');

  return (
    <Modal
      isOpen={isOpen}
      title="詳細說明"
      okText="了解"
      width={332}
      onOk={onOk}
      onCancel={onCancel}
      showCancelBtn={false}
    >
      <Content>{parse(formattedText + '')}</Content>
    </Modal>
  );
};

export default ModalDetail;
